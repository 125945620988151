define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/CalendarModule/collections/ItemType',
], (
  $, _, Backbone,
  Collection,
) => {
  const collection = Collection.extend({

    offline: true,

    loaded: false,

    load() {
      const def = new $.Deferred();

      if (!this.loaded) {
        this.fetch(this._getParams())
          .then(() => {
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    _getParams() {
      return {
        params: {
          start: 0,
          limit: 0,
        },
      };
    },

    reload() {
      if (this.loaded) this.unload();

      return this.load();
    },

    unload() {
      const cache = this.getCacheStorage();
      if (cache) {
        cache.cleanup();
      }

      this.reset();

      this.loaded = false;
    },

    getByModuleAndAlias(module_name, alias) {
      return this.findWhere({
        module_name,
        alias,
      });
    },

    getIdByModuleAndAlias(module_name, alias) {
      const model = this.getByModuleAndAlias(module_name, alias);
      if (model) return model.get('id');
    },

  });

  return new collection();
});
