define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/models/upx/Order',
  'modules/shop.cash-register-retail/collections/lastPayments',

  'backbone.deepmodel',
], (
  $, _, Backbone, AppLocalStorage, CashRegisterApi, Upx, OrderModel, LastPaymentsCollection,
) => {
  const Collection = Backbone.Collection.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/lastOrders'),

    model: Backbone.DeepModel,

    initialize() {
      this.fetch()
        .done(() => {
          this.clearOldOrders();
          this.startListeners();
        });
    },

    startListeners() {
      this.on('add', this.logAdd, this);
      this.on('remove', this.logRemove, this);
      this.on('change', this.logChange, this);
    },

    logAdd(model) {
      CashRegisterApi.logAction('ORDER_LAST_ADD', model.toJSON());
    },

    logRemove(model) {
      CashRegisterApi.logAction('ORDER_LAST_REMOVE', model.toJSON());
    },

    logChange(model) {
      CashRegisterApi.logAction('ORDER_LAST_CHANGE', model.toJSON());
    },

    /**
         * This function clears any orders that are:
         * - Not the last 5 successfull orders
         * - Cancelled orders
         * - not open orders
         */
    clearOldOrders() {
      const removeArray = [];
      let lastSuccess = {};
      const orderLimit = 5;

      // Get last successful orders
      this.each((model) => {
        if (model.get('result') !== 'success') {
          return;
        }

        const modelTime = parseInt(model.get('time'));
        const successLength = _.keys(lastSuccess).length;
        let addModel = successLength <= orderLimit; // has no items

        // Check if there is a higher item
        _.each(lastSuccess, (time) => {
          if (modelTime > time) {
            addModel = true;
          }
        });

        if (addModel) {
          // Removing the first item if there are 5 items in the lastSuccess array
          if (successLength >= orderLimit) {
            const firstId = _.first(Object.keys(lastSuccess));
            delete lastSuccess[firstId];
          }
          lastSuccess[model.get('time')] = model;

          // Sorting the object so the _.first executed earlier works.
          const newLastSuccess = {};
          Object.keys(lastSuccess).sort().forEach((key) => {
            newLastSuccess[key] = lastSuccess[key];
          });
          lastSuccess = newLastSuccess;
        }
      });

      // Ids to keep
      const keepIds = _.map(lastSuccess, (model) => model.get('id'));

      this.each((model) => {
        // Open orders are skipped
        if (!model.get('processed')) {
          return;
        }

        // Cancelled order are always removed
        if (model.get('result') === 'cancel') {
          removeArray.push(model);
          return;
        }

        // All orders that needs to be keps. (Last )
        if (keepIds.indexOf(model.get('id')) === -1) {
          removeArray.push(model);
        }
      });

      removeArray.forEach((model) => model.destroy());
    },

    /**
     *
     * @param orderId
     * @param options
     */
    saveOrder(orderId, options = {}) {
      this.add(
        {
          id: orderId,
          processed: false,
          time: new Date().getTime(),
        },
        options,
      ).save();
      this.clearOldOrders();
    },

    markAsSuccess(orderModel, logCollection, payments) {
      const model = this.get(orderModel.get('id'));
      if (model) {
        model.set({
          processed: true,
          result: 'success',
          orderModel: orderModel.toJSON(),
          processLogs: logCollection.toJSON(),
          payments: payments.toJSON(),
        }).save();
      }
    },

    getLastSuccessOrderId() {
      let id = null;
      let time = 0;
      this.each((model) => {
        if (
          model.get('processed')
                    && model.get('result') === 'success'
                    && model.get('time') > time
        ) {
          id = model.get('id');
          time = parseInt(model.get('time'));
        }
      });
      return id;
    },

    getLastSuccessOrder() {
      const lastSuccessId = this.getLastSuccessOrderId();
      if (lastSuccessId) {
        const model = this.get(lastSuccessId);
        return new OrderModel(
          model.get('orderModel'),
        );
      }
      return false;
    },

    getLastSuccessOrderLogCollection() {
      const lastSuccessId = this.getLastSuccessOrderId();
      if (lastSuccessId) {
        const model = this.get(lastSuccessId);
        return new Backbone.Collection(
          model.get('processLogs'),
        );
      }
      return false;
    },

    getLastSuccessPaymentsCollection() {
      const lastSuccessId = this.getLastSuccessOrderId();
      if (lastSuccessId) {
        const model = this.get(lastSuccessId);
        return new LastPaymentsCollection(
          model.get('payments'),
        );
      }
      return null;
    },

    cancelAllUnprocessedOrders() {
      const ids = [];
      this.map((model) => {
        if (
          !model.get('processed')
        ) {
          ids.push(model.get('id'));
        }
      });

      if (ids.length) {
        // make sure they ware not changed yet
        Upx.call('ShopModule', 'listOrderIds',
          {
            filters: [
              {
                name: 'status__in_list',
                multi_val: ['concept', 'new'], // no changes to order yet
              },
              {
                name: 'id__in_list',
                multi_val: ids,
              },
            ],
          }).then(
          (ordersIds) => {
            ids.forEach((id) => {
              if (ordersIds.indexOf(id) > -1) {
                this.cancelOrder(id);
              } else {
                this.markAsCanceled(id);
              }
            });
          },
        );
      }
    },

    cancelOrder(orderId) {
      const model = this.get(orderId);
      if (model) {
        const orderId = model.get('id');
        Upx.callWithRetry(
          () => Upx.call('ShopModule', 'markOrderAsCanceled',
            {
              id: orderId,
            }),
        ).then(
          () => {
            this.markAsCanceled(orderId);
          },
          () => {
            CashRegisterApi.logAction('ORDER_FAILED_TO_CANCEL', {
              orderId,
            });
          },
        );
      }
    },
    markAsCanceled(orderId) {
      const model = this.get(orderId);
      if (model) {
        model.set({
          processed: true,
          result: 'cancel',
        }).save();
      }
    },

    load() {
      const def = $.Deferred();
      this.fetch()
        .done(() => {
          def.resolve();
        });
      return def;
    },

  });

  return new Collection();
});
