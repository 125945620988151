define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/Order',
  'modules/shop.cash-register-retail/models/upx/Order',
], (
  $, _, Backbone, OrderCollection, OrderModel,
) => OrderCollection.extend({

  model: OrderModel,

}));
