define([
  'upx.modules/ShippingModule/collections/TranslatedShippingMethod',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/shipmentMethodType',
], (
  UpxCollection,
  Locale, ShipmentMethodType,
) => {
  const Collection = UpxCollection.extend({

    loadParameters: {
      start: 0,
      limit: 200,
      lang: Locale.getLocale(),
    },

    getFilteredCollection(filter) {
      return new UpxCollection(this.filter(filter));
    },

    getEnabledCollection() {
      return this.getFilteredCollection(((model) => model.get('enabled')));
    },

    getEnabledPickupAtStoreCollection() {
      const enabledCollection = this.getEnabledCollection();

      return new UpxCollection(enabledCollection.filter((model) => model.get('shipping_type.alias') === ShipmentMethodType.TYPE_PICK_AT_STORE));
    },
  });

  return new Collection();
});
