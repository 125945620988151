define([
  'jquery',
  'modules/shop.common/collections/upx/FeaturedAttribute',
  'modules/shop.cash-register-retail/models/pinKey',
],
($, UpxCollection, PinKey) => {
  const model = UpxCollection.CLASS.extend({

    load(force) {
      const def = $.Deferred();
      const self = this;
      if (!this.loaded || force) {
        // calling with pinKey to be sure it also works on the login screen
        PinKey.callWithAuthIfEnabled('ProductsModule', 'listFeaturedAttributes', {
          params: {
            start: 0,
            limit: 0,
          },
        }).then(
          (response) => {
            if (response.total > 0) {
              $.each(response.data, (i, attr) => {
                self.add(attr);
              });
            }
            self.loaded = true;
            def.resolve();
          },
          (error) => {
            def.reject('Failed to load the FeaturedAttribute');
          },
        );
      } else {
        def.resolve();
      }

      return def.promise();
    },

    getFeatureAttributesFromShopFlatProduct(shopProduct) {
      const attributes = {};
      const featured = {};
      this.each((attr) => {
        featured[attr.get('attribute.id')] = attr.get('alias');
      });
      const content_vars = shopProduct.get('flat_product.content_vars') || [];
      content_vars.forEach((content_var) => {
        if (!!content_var.attribute_id && content_var.attribute_id in featured) {
          attributes[featured[content_var.attribute_id]] = content_var;
        }
      });
      return attributes;
    },
    getFeatureAttributeByAliasFromShopFlatProduct(shopProduct, alias) {
      const attributes = this.getFeatureAttributesFromShopFlatProduct(shopProduct);
      if (
        attributes[alias]
        && attributes[alias].value
      ) {
        return attributes[alias].value;
      }
      return null;
    },

    setFeatureAttributeByAliasFromShopFlatProduct(shopProduct, alias, value) {
      const attr = this.where({ alias });
      let isSet = false;
      if (attr.length === 0) {
        return isSet;
      }
      const attributeId = attr[0].get('attribute_id');
      const content_vars = shopProduct.get('flat_product.content_vars') || [];
      content_vars.forEach((content_var) => {
        if (!!content_var.attribute_id && content_var.attribute_id === attributeId) {
          content_var.value = value;
          isSet = true;
        }
      });
      shopProduct.set('flat_product.content_vars', content_vars);

      return isSet;
    },
  });

  return new model();
});
