define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',
], (
  $, _, Backbone, PaymentMethods,
) => Backbone.Collection.extend({
  hasCCVPayment() {
    return this.some((payment) => {
      const paymentMethod = payment.get('paymentMethod');

      if (paymentMethod.id === PaymentMethods.PIN_METHOD) {
        return paymentMethod.isCCVPayment;
      }

      return false;
    });
  },
}));
