define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/collections/Table',
  'modules/shop.cash-register-retail/models/table',
], (
  $, _, Backbone, TableCollection, TableModel,
) => TableCollection.extend({

  model: TableModel,

}));
