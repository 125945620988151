define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'backbone.deepmodel',
], (
  $, _, Backbone, AppLocalStorage, CashRegisterApi, OrderItemCollection,
) => {
  const Collection = Backbone.Collection.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/orderMemory'),

    model: Backbone.DeepModel,

    clearOlderThen24h() {
      // remove all orders older than 24h
      const dayAgo = new Date().getTime() - (24 * 60 * 60 * 1000);
      const removeArray = [];
      this.each((model) => {
        const time = model.get('id');
        if (time < dayAgo) {
          removeArray.push(model);
        }
      });

      removeArray.forEach((model) => model.destroy());
    },

    initialize() {
      const self = this;
      this.fetch()
        .done(() => {
          self.clearOlderThen24h();

          self.startListeners();
        });
    },

    startListeners() {
      this.on('add', this.logAdd, this);
      this.on('remove', this.logRemove, this);
      this.on('change', this.logChange, this);
    },

    logAdd(model) {
      CashRegisterApi.logAction('ORDER_MEMORY_ADD', model.toJSON());
    },

    logRemove(model) {
      CashRegisterApi.logAction('ORDER_MEMORY_REMOVE', model.toJSON());
    },

    logChange(model) {
      CashRegisterApi.logAction('ORDER_MEMORY_CHANGE', model.toJSON());
    },

    /**
         * stores current order if it makes sense
         * @returns {boolean}
         */
    restoreCurrent(model) {
      OrderItemCollection.clear(); // clear items
      const items = model.get('order_items');
      OrderItemCollection.add(items);
    },

    load() {
      const def = $.Deferred();
      this.fetch()
        .done(() => {
          def.resolve();
        });
      return def;
    },

    clear() {
      const mdls = [];
      this.each((model) => {
        mdls.push(model);
      });
      mdls.forEach((model) => {
        model.destroy();
      });
    },

  });

  return new Collection();
});
