define([
  'backbone',

  'modules/shop.common/components/crypto',
  'modules/common/components/appLocalStorage',
], (
  Backbone,
  Crypto, AppLocalStorage,
) => {
  const Collection = Backbone.Collection.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/routeCache'),

    initialize() {
      this.fetch()
        .then(() => {
          this.cleanExpired();
        });
    },

    getExpiryDate() {
      return new Date().getTime() + (1000 * 60 * 60 * 24 * 7); // 7 days
    },

    cleanExpired() {
      const currentTimestamp = new Date().getTime();
      const expiredModels = this.filter((model) => model.get('expiry_date') < currentTimestamp);
      expiredModels.forEach((model) => model.destroy());
    },

    createCache(data) {
      const id = Crypto.shortid();
      const expiry_date = this.getExpiryDate();
      const model = this.add({ id, expiry_date, data });

      model.save();

      return model.get('id');
    },

    getCache(cacheId, fallback = null) {
      const cacheModel = this.get(cacheId);

      if (!cacheModel) return fallback;

      cacheModel.set('expiry_date', this.getExpiryDate());
      cacheModel.save();

      return cacheModel.get('data');
    },

    hasCache(id) {
      return this.has(id);
    },

  });

  return new Collection();
});
