define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/collections/paymentMethodItem',
  'modules/shop.cash-register-retail/models/currentPaymentMethodItem',

  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/common/components/appLocalStorage',
], (
  $, _, Backbone,
  PaymentMethodItemCollection, CurrentPaymentMethodItemModel,
  PaymentMethodsSettingModel,
  AppLocalStorage,
) => {
  const Collection = PaymentMethodItemCollection.extend({

    model: CurrentPaymentMethodItemModel,

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/currentPaymentMethodItem'),

    initialize(options) {
      PaymentMethodItemCollection.prototype.initialize.call(this, options);
      this.fetch();
    },

    reFetch() {
      return this.load();
    },

    load() {
      const def = new $.Deferred();
      this.fetch()
        .done(() => {
          def.resolve();
        });
      return def;
    },

    clear() {
      const models = this.map((model) => model);
      models.forEach((model) => model.destroy());
      this.setTotalPriceWt(null);
    },
  });

  return new Collection();
});
