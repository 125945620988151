define([
  'jquery',
  'module',
  'upx.modules/OnlineFoodOrderModule/collections/OrderProvider',
],
(
  $, Module, UpxCollection,
) => {
  const Collection = UpxCollection.extend({
    loadSortName: 'shop_id',
    offline: false,
    loadParameters: {
      start: 0,
      limit: 250,
      filters: [
        {
          name: 'shop/published__=',
          val: '1',
        },
        {
          name: 'shop/deleted__=',
          val: '0',
        },
      ],
    },

  });

  return new Collection();
});
