define([
  'modules/shop.cash-register-retail/collections/table',
], (
  TableCollection,
) => {
  const Collection = TableCollection.extend({

    loadParameters: {
      start: 0,
      limit: 250,
      sort: [{
        name: 'name',
        dir: 'asc',
      }],
      filters: [{
        name: 'table_area/is_active__=',
        val: true,
      }],
    },

  });

  return new Collection();
});
