define([
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/keyboard',
],
(_, Backbone, Keyboard) => Backbone.Marionette.Behavior.extend({

  events: {
    click: 'handleCellClicked',
  },

  initialize(options) {
    options = options || {};

    this.preventRender = !!options.preventRender;
    this.focusElSelector = options.focusElSelector;
    this.resetOnConfirmation = _.isBoolean(options.resetOnConfirmation) ? options.resetOnConfirmation : true;
    this.resetOnCancel = _.isBoolean(options.resetOnConfirmation) ? options.resetOnConfirmation : true;
    this.view.isFocused = false;
    this.view.firstInput = true;
    this.view.dotPos = -1;
    this.view.keyboardModel = Keyboard;
    this.view.handleInputOnPrice = _.bind(this.handleInputOnPrice, this.view);
    this.view.removeDigitFromPrice = _.bind(this.removeDigitFromPrice, this.view);
    this.view.removeLastCharacterFromString = _.bind(this.removeLastCharacterFromString, this.view);
    this.view.isMyKeyboardMode = () => this.isMyKeyboardMode();
  },

  // function binded to view
  handleInputOnPrice(currentPrice, key) {
    const priceExplode = currentPrice.split('.');
    let preDot = priceExplode[0];
    let postDot = priceExplode[1];

    if (key === '00') {
      postDot = '00';
      this.dotPos = 0;
    } else if (key === ',' || key === '.') {
      this.dotPos = 0;
    } else if (this.dotPos < 0) {
      if (preDot === '0') {
        preDot = key;
      } else {
        preDot += key;
      }
    } else if (this.dotPos < 2) {
      const explosion = postDot.split('');
      explosion[this.dotPos] = key;
      postDot = explosion.join('');
      this.dotPos++;
    }

    return [preDot, postDot].join('.');
  },

  removeLastCharacterFromString(string) {
    if (typeof string !== 'string') {
      console.warn('Given parameter string is not of type string', string);
      return '';
    }

    return string.substring(0, string.length - 1);
  },

  removeDigitFromPrice(currency) {
    if (typeof currency !== 'string') {
      console.warn('Given parameter currency is not of type string', currency);
      return '0.00'; // Return reset/empty price
    }

    // extracting the before and after value
    let [before, after] = currency.split('.');
    // Check if after if higher then 0
    if (parseInt(after) > 0) {
      let [first, second] = after.split('');
      if (parseInt(second) > 0) {
        second = 0;
        this.dotPos = 1;
      } else if (parseInt(first) > 0) {
        first = 0;
        this.dotPos = 0;
      }
      after = `${first}${second}`;
    } else if (parseInt(before) > 0) {
      this.dotPos = -1;
      // Else check if before if higher then 0
      const withoutLastDigitRegex = /(^\d+)\d/;
      if (withoutLastDigitRegex.test(before)) {
        before = withoutLastDigitRegex.exec(before)[1];
      } else {
        before = 0;
      }
    } else {
      // reset the dotPos to before the dotPos
      this.dotPos = -1;
    }

    return `${before}.${after}`;
  },

  onShow() {
    if (this.getIsEditable()) {
      Keyboard.on('change:mode', this.modeChange, this);
      Keyboard.on('change:viewId', this.modeChange, this);
      Keyboard.on('key:pressed', this.handleKeyPress, this);
      Keyboard.on('key:confirm', this.handleConfirmation, this);
      Keyboard.on('key:backspace', this.handleBackspace, this);
      Keyboard.on('key:cancel', this.handleCancel, this);
    }
  },

  onDestroy() {
    if (this.getIsEditable()) {
      Keyboard.off('change:mode', this.modeChange, this);
      Keyboard.off('change:viewId', this.modeChange, this);
      Keyboard.off('key:pressed', this.handleKeyPress, this);
      Keyboard.off('key:confirm', this.handleConfirmation, this);
      Keyboard.off('key:backspace', this.handleBackspace, this);
      Keyboard.off('key:cancel', this.handleCancel, this);
      this.deselectIfFocused();
    }
    if (Keyboard.get('viewId') === this.view.cid) {
      Keyboard.setNeutralMode();
    }
  },

  getFocusEl() {
    if (!this.$focusEl) {
      this.$focusEl = this.$el;
      if (this.focusElSelector) {
        this.$focusEl = this.$el.find(this.focusElSelector);
      }
    }
    return this.$focusEl;
  },

  getKeyboardMode() {
    if (!this.view.keyboardMode) {
      throw new Error('Need keyboardMode');
    }
    return this.view.keyboardMode;
  },

  getIsEditable() {
    if ('isEditable' in this.view) {
      return !!this.view.isEditable;
    }
    if (this.view.options && 'isEditable' in this.view.options) {
      return !!this.view.options.isEditable;
    }
    return true;
  },
  isMyKeyboardMode() {
    return Keyboard.get('mode') === this.getKeyboardMode()
                    && Keyboard.get('viewId') === this.view.cid;
  },

  deselectIfFocused() {
    if (this.view.isFocused) {
      this.view.isFocused = false;
      this.getFocusEl().removeClass('element-focus');
      this.onModeDeselected();
      this.onCancel(); // just call cancel to make sure it`s cleaned
      if (this.isMyKeyboardMode()) {
        Keyboard.resetMode(); // To make the mode is reset.
      }
    }
  },
  modeChange() {
    if (this.isMyKeyboardMode()) {
      if (!this.view.isFocused) {
        this.view.isFocused = true;
        this.view.firstInput = true;
        this.getFocusEl().addClass('element-focus');
        this.onModeSelected();
      }
    } else {
      this.deselectIfFocused();
    }
  },

  renderView() {
    if (!this.preventRender) {
      if (!this.view.isDestroyed) {
        this.view.render();
      }
    }
  },

  /** ========================
             * all on* functions can be overwriten in the view to change behavior
             * functions:
             * ====================== */
  onCellClicked() {
    const name = 'onCellClicked';
    if (name in this.view) {
      this.view[name]();
    } else if (this.getIsEditable()) {
      Keyboard.setViewWithMode(this.view.cid, this.getKeyboardMode());
    }
  },
  afterCellClicked() {
    const name = 'afterCellClicked';
    if (name in this.view) {
      this.view[name]();
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onModeSelected() {
    const name = 'onModeSelected';
    if (name in this.view) {
      this.view[name]();
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onModeDeselected() {
    const name = 'onModeDeselected';
    if (name in this.view) {
      this.view[name]();
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onKeyPress(key) {
    const name = 'onKeyPress';
    if (name in this.view) {
      this.view[name](key);
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onBackspacePress(key) {
    const name = 'onBackspacePress';
    if (name in this.view) {
      this.view[name](key);
    } else {
      this.view.dotPos = -1; // View does not implements the dotPos resetting
      console.warn(`${name} not implemented`);
    }
  },
  onCancelPressed(key) {
    const name = 'onCancelPressed';
    if (name in this.view) {
      this.view[name](key);
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onConfirmation() {
    const name = 'onConfirmation';
    if (name in this.view) {
      this.view[name]();
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onCancel() {
    const name = 'onCancel';
    if (name in this.view) {
      this.view[name]();
    } else {
      console.warn(`${name} not implemented`);
    }
  },
  onRenderInput() {
    const name = 'onRenderInput';
    if (name in this.view) {
      this.view[name]();
    } else {
      this.renderView();
    }
  },

  handleCellClicked() {
    this.onCellClicked();
    this.afterCellClicked();
  },

  handleBackspace() {
    if (this.isMyKeyboardMode()) {
      this.onBackspacePress();

      this.renderView();
    }
  },

  handleConfirmation() {
    if (this.isMyKeyboardMode()) {
      this.onConfirmation();

      if (this.resetOnConfirmation) {
        Keyboard.resetMode();
      }
      this.view.dotPos = -1;
    }
  },

  handleCancel() {
    if (this.isMyKeyboardMode()) {
      this.onCancel();
      this.onCancelPressed();
      this.renderView();

      if (this.resetOnCancel) {
        Keyboard.resetMode();
      }
      this.view.dotPos = -1;
    }
  },

  handleKeyPress(key) {
    if (this.isMyKeyboardMode()) {
      this.onKeyPress(key);

      this.renderView();
    }
  },
}));
