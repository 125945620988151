define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/inputs/shippingMethodInput.hbs',
  'modules/shop.cash-register-retail/behaviors/select2Input',

  'modules/shop.cash-register-retail/collections/ShippingMethodCollection',

  'modules/shop.cash-register-retail/components/shipmentMethodType',
  'modules/admin/components/select2',
], (
  $, _, Backbone, Template, Select2Input,
  ShippingMethodCollection,
  ShipmentMethodType,
) => Select2Input.extend({

  initialize(options) {
    Select2Input.prototype.initialize.call(this, options);

    if (!ShippingMethodCollection.isLoaded()) {
      throw new Error('Shipping method is not yet loaded, component is not usable');
    }
  },

  defaults() {
    return {
      selectors: '[data-input="shipping-method"]',
    };
  },

  createField(el) {
    const self = this;
    const $el = $(el);
    $el.select2({
      containerCssClass: 'container-white form-control',
      dropdownCssClass: 'dropdown-white',
      minimumInputLength: 0,

      formatResult({ id, text }) {
        return self.formatWithIcon(id, text);
      },

      formatSelection({ id, text }) {
        return self.formatWithIcon(id, text);
      },
    });
  },

  formatWithIcon(id, text) {
    const shippingMethodModel = ShippingMethodCollection.get(id);
    let shippingTypeIcon = null;

    if (shippingMethodModel) {
      shippingTypeIcon = ShipmentMethodType.getIconForType(shippingMethodModel.get('shipping_type.alias'));
    }

    return Template({
      icon: shippingTypeIcon,
      text,
    });
  },
}));
