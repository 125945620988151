define([
  'jquery',
  'underscore',
  'backbone',

  'upx.modules/ShopModule/collections/ConfigurationAttachedGroup',
  'upx.modules/ShopModule/collections/Configuration',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  $, _, Backbone,
  ConfigurationAttachedGroupCollection, ShopConfigurationCollection, DefaultShopConfigurationModel,
) => {
  const Collection = ConfigurationAttachedGroupCollection.extend({

    offline: true,

    loaded: false,

    /**
         * Load the collection
         */
    load(force) {
      const def = new $.Deferred();

      if (!this.loaded || force) {
        DefaultShopConfigurationModel.load().then(() => {
          const fetchParameters = {
            start: 0,
            limit: 0,
            filters: [{
              name: 'configuration_id__=',
              val: DefaultShopConfigurationModel.get('id'),
            }],
          };

          this.fetch({ params: fetchParameters })
            .then(() => {
              this.loaded = true;
              def.resolve();
            }, def.reject);
        }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    /**
         * Unload the collection
         */
    unload() {
      this.loaded = false;
      this.reset();
      this.getCacheStorage().cleanup();
    },

    reload() {
      if (this.loaded) {
        this.unload();
      }

      return this.load();
    },

    getByAlias(alias) {
      let group = null;

      this.each((model) => {
        if (!group) {
          const types = model.get('group_types') || [];
          types.forEach((type) => {
            if (!group && type.alias === alias) {
              group = model;
            }
          });
        }
      });

      return group;
    },

    getIdByAlias(alias) {
      const group = this.getByAlias(alias);
      if (group) {
        return group.get('group_id');
      }
    },

  });

  return new Collection();
});
