define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/locale',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',
  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',
], (
  $, _, Backbone,
  Locale, DeviceConfig, NaturalSearchShopFlatProduct, FeaturedAttributeCollection,
) => {
  let loadDef = new $.Deferred(); // to make sure nobody touches it
  loadDef.resolve();// not working

  const Collection = Backbone.Collection.extend({

    comparator: 'id',

    getLoadDef() {
      return loadDef.promise();
    },

    loadFromConfig() {
      if (loadDef.state() === 'pending') {
        // loading right now
        console.warn('[CheckoutButtonsCollection] loadFromConfig requested while already loading');
      } else {
        try {
          loadDef = this.loadFromConfigCall();
        } catch (e) {
          loadDef.reject('Call error'); // reject cos broken
          console.error(e);
        }
      }
      return this.getLoadDef();
    },

    loadFromConfigCall() {
      this.reset(); // always reset

      const def = new $.Deferred();
      const buttonCollection = DeviceConfig.collectionFilteredWithPrefix(`${DeviceConfig.PATH_CheckoutButtons}.`);
      const naturalSearchShopFlatProduct = new NaturalSearchShopFlatProduct();
      const ids = [];
      buttonCollection.each((model) => {
        if (model.has('extra.shop_product_id')) {
          ids.push(model.get('extra.shop_product_id'));
        }
      });
      const params = {
        query: 0,
        lang: 0,
        start: 0,
        limit: 0,
        filters: [{
          name: 'id__in_list',
          multi_val: ids,
        }, {
          name: 'product_price/ppu_wt__notnull',
          val: true,
        }],
      };
      FeaturedAttributeCollection.load()
        .then(() => {
          naturalSearchShopFlatProduct.fetch({ params })
            .then(() => {
              buttonCollection.each((buttonModel) => {
                const buttonAlias = buttonModel.get('alias');
                const buttonId = parseInt(buttonAlias.substr(16));
                // only first 18 buttons fit the checkout page
                if (buttonId < 18) {
                  const shopProductId = buttonModel.get('extra.shop_product_id');
                  const model = naturalSearchShopFlatProduct.get(shopProductId);
                  const prodData = model ? model.toJSON() : null;
                  this.add({
                    type_alias: `${buttonModel.get('device_config_type.module_name')}::${buttonModel.get('device_config_type.alias')}`,
                    category_id: buttonModel.get('extra.category_id'),
                    category_title: buttonModel.get('extra.category_title'),
                    tax_rate_id: buttonModel.get('extra.tax_rate_id'),
                    name: buttonModel.get('extra.name'),
                    color: buttonModel.get('extra.class'),
                    flat_shop_product: prodData,
                    id: buttonId,
                  });
                }
              });

              // fillup empty items
              for (let i = 0; i < 18; i++) {
                if (!this.get(i)) {
                  this.add({
                    type_alias: 'ShopModule::DashboardButtonEmpty',
                    id: i,
                  });
                }
              }

              def.resolve();
            }, def.reject);
        }, def.reject);

      return def;
    },

  });

  return new Collection();
});
