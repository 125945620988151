define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/collections/NaturalSearchShopFlatProduct',

  'modules/shop.cash-register-retail/collections/upx/FeaturedAttribute',

  'modules/common/components/moment',
], (
  $, _, Backbone, NaturalSearchShopFlatProductCollection,
  FeaturedAttributeCollection,
  Moment,
) => {
  const Collection = NaturalSearchShopFlatProductCollection.extend({

    dateLastFetch: null,

    load() {
      const def = new $.Deferred();

      if (this.allowNextFetch()) {
        FeaturedAttributeCollection.load()
          .then(() => {
            this.fetchAll(this._getParameters())
              .then(() => {
                this.dateLastFetch = new Moment();
                def.resolve();
              }, def.reject);
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    allowNextFetch() {
      // Check if there was ever a fetch
      if (!this.dateLastFetch) return true;

      // Check if the last fetch was more then an 30 minutes
      const currentDate = new Moment();
      return currentDate.diff(this.dateLastFetch, 'minutes') > 30;
    },

    _getParameters() {
      const parameters = {
        query: 0,
        lang: 0,
        start: 0,
        limit: 250,
        sort: [{
          name: 'flat_product/title',
          dir: 'asc',
        }],
        filters: [
          {
            name: 'flat_product/content_var_attribute_ids__overlap',
            multi_val: [
              FeaturedAttributeCollection.getAttributeIdByAlias(FeaturedAttributeCollection.ALIAS_DURATION_IN_SECONDS),
            ],
          },
          {
            name: 'flat_product/product/type__in_list',
            multi_val: [
              'simple',
              'configurable_assign',
            ],
          },
          {
            name: 'active__=',
            val: true,
          },
        ],
      };

      // Add last fetch parameter
      if (this.dateLastFetch) {
        parameters.filters.push({
          name: 'date_updated__>',
          val: this.dateLastFetch.format(),
        });
      }

      return {
        params: parameters,
        add: true,
        merge: true,
        remove: false,
      };
    },

    unload() {
      this.reset();
      this.dateLastFetch = null;
    },

  });

  return new Collection();
});
