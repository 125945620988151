define([
  'jquery',
  'module',
  'upx.modules/OnlineFoodOrderModule/collections/OnlineOrder',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrderProvider',
],
(
  $, Module, UpxCollection, Moment, OnlineFoodOrderProvider,
) => {
  const Collection = UpxCollection.extend({
    loadSortName: 'id',
    offline: false,
    loadParameters: {
      start: 0,
      limit: 250,
      sort: [{
        name: 'date_expected',
        dir: 'asc',
      }],
      filters: [],
    },

    getRecentStartDate() {
      const date = new Moment();
      const hour = parseInt(date.format('H'), 10);
      if (hour < 7) {
        date.subtract(1, 'day');
      }
      return date.hour(7).minute(0).second(0).millisecond(0);
    },

    _getLoadParameters({ update }) {
      const params = UpxCollection.prototype._getLoadParameters.call(this, { update });
      params.filters.push({
        name: 'date_ordered__>=',
        val: this.getRecentStartDate().format(),
      });
      params.filters.push({
        name: 'order_provider/shop_id__in_list',
        multi_val: OnlineFoodOrderProvider.pluck('shop_id'),
      });
      return params;
    },

    load({ reload = false, update = true } = {}) {
      const def = UpxCollection.prototype.load.call(this, { reload, update });
      def.then(
        () => {
          const startDate = this.getRecentStartDate();
          const removeModels = [];
          this.each((model) => {
            const ordered = new Moment(model.get('date_ordered'));
            if (ordered.isBefore(startDate)) {
              removeModels.push(model);
            }
          });
          this.remove(removeModels);
        },
      );
      return def.promise();
    },

    getNewOrderCount() {
      return this.where({
        status: 'new',
      }).length;
    },
  });

  return new Collection();
});
