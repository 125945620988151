define([
  'upx.modules/ShopModule/collections/TableArea',
], (
  TableAreaCollection,
) => {
  const Collection = TableAreaCollection.extend({

    loadParameters: {
      start: 0,
      limit: 250,
      sort: [{
        name: 'name',
        dir: 'asc',
      }],
      filters: [{
        name: 'is_active__=',
        val: true,
      }],
    },

  });

  return new Collection();
});
