define([
  'jquery',
  'underscore',
  'backbone',

], (
  $, _, Backbone,
) => {
  const Model = Backbone.Model.extend({
    defaults: {
      refundable: false,
      refunded: false,
      error: false,
    },

    toJSON() {
      const res = $.extend(true, {}, this.attributes);
      if (res.error && res.error.paymentResults) {
        delete res.error.paymentResults;
      }
      return res;
    },
  });

  const Collection = Backbone.Collection.extend({
    model: Model,

    addSuccessful(model, payment_id, isRefundable) {
      if (isRefundable === undefined) {
        isRefundable = false;
      }
      isRefundable = !!isRefundable && !!payment_id;
      return this.add(new Model({
        payment_id,
        paymentMethod: model.toJSON(),
        refundable: isRefundable,
      }));
    },
    addError(model, error, payment_id) {
      if (typeof error === 'string') {
        error = { error };
      }
      return this.add(new Model({
        payment_id,
        paymentMethod: model.toJSON(),
        error,
      }));
    },

    getRefundable() {
      return new Collection(this.where({ refundable: true }));
    },
    getRefunded() {
      return new Collection(this.where({ refunded: true }));
    },

    getPaymentIds() {
      const withPayments = this.filter(
        (model) => !!model.get('payment_id'),
      );
      return _.map(withPayments,
        (model) => model.get('payment_id'));
    },
  });
  return Collection;
});
