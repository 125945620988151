define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/appLocalStorage',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

  'modules/shop.cash-register-retail/collections/orderItem',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
], (
  $, _, Backbone,
  AppLocalStorage, CashRegisterApi,
  OrderItemCollection, ShopConfiguration,
) => {
  const Collection = OrderItemCollection.extend({

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/collections/currentOrderItem'),

    initialize() {
      const self = this;
      this.fetch()
        .done(() => {
          const removeArray = [];
          self.each((model) => {
            if (model.get('type') === OrderItemCollection.TYPE_LOADER) {
              removeArray.push(model);
            }
          });

          self.remove(removeArray);
        });

      this.startListeners();
    },

    startListeners() {
      this.on('add', this.logAdd, this);
      this.on('remove', this.logRemove, this);
      this.on('change', this.logChange, this);
    },

    logAdd: _.debounce((model) => {
      if (model && model.get('type') !== 'type-loader') {
        CashRegisterApi.logAction('ORDER_ITEM_ADD', model.toJSON());
      }
    }),

    logRemove: _.debounce((model) => {
      if (model && model.get('type') !== 'type-loader') {
        CashRegisterApi.logAction('ORDER_ITEM_REMOVE', model.toJSON());
      }
    }),

    logChange: _.debounce((model) => {
      if (model && model.get('type') !== 'type-loader') {
        CashRegisterApi.logAction('ORDER_ITEM_CHANGE', model.toJSON());
      }
    }),

    reFetch() {
      return this.load();
    },

    load() {
      const def = $.Deferred();
      this.fetch()
        .done(() => {
          def.resolve();
        });
      return def;
    },

    addProductByOrderModel(orderModel, shouldClean = false) {
      if (shouldClean) {
        this.clear();
      }

      const orderItems = orderModel.get('order_items');
      orderItems.forEach((orderItem) => {
        if (
          orderItem.quantity > 0
            && !ShopConfiguration.getInvalidProductsForReorder().includes(orderItem.product_id)
        ) {
          this.addProductByOrderItem({ orderItem, forceNew: true });
        }
      });
    },

    clear() {
      // fetch to make sure all is removed
      this.fetch();

      const mdls = [];
      this.each((model) => {
        mdls.push(model);
      });
      mdls.forEach((model) => {
        model.destroy();
      });

      // force clean the local storage to make sure there is nothing left
      localStorage.removeItem(this.localStorage.name);
    },

  });

  return new Collection();
});
