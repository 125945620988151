define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/behaviors/behavior',

  'modules/admin/components/select2',
], (
  $, _, Backbone, Behavior,
) => Behavior.extend({

  defaults: {
    selectors: ['[data-input="select2"]'],
  },

  onRender() {
    this.getSelectors().forEach((selector) => this.createSelect2(selector));
  },

  createSelect2(selector) {
    _.each(this.$el.find(selector).get(), (el) => this.createField(el));
  },

  createField(el) {
    const $el = $(el);
    $el.select2({
      containerCssClass: 'container-white form-control',
      dropdownCssClass: 'dropdown-white',
      minimumInputLength: 0,
      width: '100%',
    });
  },

  getSelectors() {
    let { selectors } = this.options;
    if (typeof selectors === 'string') {
      selectors = [selectors];
    }
    return selectors;
  },
}));
