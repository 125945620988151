define([
  'jquery',
  'underscore',
  'module',
  'upx.modules/ShopModule/collections/LoyaltyOperationType',

  'modules/common/components/locale',
], (
  $, _, Backbone, LoyaltyOperationTypeCollection, Locale,
) => {
  const Collection = LoyaltyOperationTypeCollection.extend({

    loaded: false,

    load(force) {
      force = force || false;

      const def = new $.Deferred();

      if (force) this.unload();

      if (!this.loaded) {
        const parameters = {
          start: 0,
          limit: 0,
        };

        this.fetch({ params: parameters })
          .then(() => {
            this.addTitles();
            this.loaded = true;
            def.resolve();
          }, def.reject);
      } else {
        def.resolve();
      }

      return def;
    },

    addTitles() {
      this.each((model) => {
        model.set('title', this.getTitle(model));
      });
    },

    getTitle(model) {
      const alias = model.get('alias');
      if (alias === 'CouponCode') {
        return Locale.translate('coupon_code');
      }
      if (alias === 'GiftCardTopUp') {
        return Locale.translate('gift_card_top_up');
      }
      if (alias === 'OrderDiscount') {
        return Locale.translate('discount_on_order');
      }
      if (alias === 'ProductDiscount') {
        return Locale.translate('discount_on_product_or_free_product');
      }
      if (alias === 'WriteOff') {
        return Locale.translate('write_off');
      }
      if (alias === 'InvoicePaid') {
        return Locale.translate('invoice_was_paid');
      }
      if (alias === 'Other') {
        return Locale.translate('other');
      }
      console.error(`Unknown alias: ${alias}`);
      return '';
    },

    unload() {
      this.reset();
      this.loaded = false;
    },

  });

  return new Collection();
});
